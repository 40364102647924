import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapSpotEmbed from './MapSpotEmbed';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Map({ contents, latlng, h, w }) {
  const defaultProps = {
    center: {
      lat: 40.77662516550977,
      lng: -73.98217964458655,
    },
    zoom: 15,
  };
  if (!latlng) {
    latlng = defaultProps.center;
  }

  return (
    // Important! Always set the container height explicitly
    <div
      style={{ height: h || '500px', width: w || '95%' }}
      className="mx-auto p-1"
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_JAJ_GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapSpotEmbed lat={latlng.lat} lng={latlng.lng} contents={contents} />
      </GoogleMapReact>
    </div>
  );
}
