import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Teachers from "../models/Teachers";
import ZipSearch from "../components/ZipSearch";

const TeachersPage = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    async function getTeachers() {
      const res = await Teachers.getAll();
      setTeachers(res);
    }
    getTeachers();
  }, []);

  const loaded = () => {
    return <Outlet context={{ teachers }} />;
  };

  return (
    <Container fluid="md" className="mt-4 pt-2 text-center">
      <h1>Teachers</h1>
      <h5 className="my-3">Hire local teachers in specific subjects to help in your homeschooling journey!</h5>
      <ZipSearch />
      {teachers.length ? loaded() : <h4>Loading...</h4>}
    </Container>
  );
};

export default TeachersPage;
