import { useState, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UserAuth from "../models/UserAuth";
import FormParent from "../components/FormParent";
import FormTeacher from "../components/FormTeacher";

const SignUp = () => {
  const { userState } = useOutletContext();
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const topScrollRef = useRef(null);
  const bottomScrollRef = useRef(null);

  const [userForm, setUserForm] = useState({
    role: "Parent",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    displayName: "",
    zipCode: "",
    streetAddress1: "",
    streetAddress2: "",
    about: "",
    hashtags: "",
    child1DOB: "",
    child1Sex: "",
    child2DOB: "",
    child2Sex: "",
    child3DOB: "",
    child3Sex: "",
    child4DOB: "",
    child4Sex: "",
    teachSubjects: "",
    teachGradeLevels: "",
    teachInpersonOnline: "",
    teachGroupPrivate: "",
    teachAbout: "",
  });

  const validate = () => {
    let isValid = true;
    if (userForm.password !== confirmPassword) {
      isValid = false;
      setErrors({ ...errors, password: "Passwords don't match." });
      topScrollRef.current.scrollIntoView();
    }
    return isValid;
  };

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (e) => {
    console.log(e);
    setUserForm({ ...userForm, role: e });
  };

  const handleConfirmChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userForm);
    if (validate()) {
      const newUser = await UserAuth.register(userForm);
      if (newUser) {
        console.log("New user registration succeeded.");
        setUser(newUser);
        navigate("/community");
      } else {
        setErrors({ submit: "Couldn't register new user. Please try again." });
        bottomScrollRef.current.scrollIntoView();
        console.log("New user registration failed.");
      }
    }
  };

  return (
    <Container className="mt-4 pt-1">
      <Card className="sign-up-card" ref={topScrollRef}>
        <h2 className="text-center">Sign Up</h2>
        <Form onSubmit={handleSubmit} className="mt-3 mb-2">
          <div className="d-flex justify-content-center mb-3">
            <div className="m-2 p-2">
              <h6>My role:</h6>
            </div>
            <div className="mt-1 pt-1">
              <DropdownButton
                id="dropdown-basic-button"
                title={userForm.role}
                onSelect={handleRoleChange}
                className="m-0 p-0"
              >
                <Dropdown.Item eventKey="Parent">Parent</Dropdown.Item>
                <Dropdown.Item eventKey="Teacher">Teacher</Dropdown.Item>
                <Dropdown.Item eventKey="Both">Parent & Teacher</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <FloatingLabel label="Email Address *" className="mb-3">
            <Form.Control
              type="email"
              name="email"
              value={userForm.email}
              onChange={handleChange}
              placeholder="janedoe123@example.com"
              required
            />
          </FloatingLabel>

          <Row className="mb-3">
            <Col md>
              <FloatingLabel label="Password *">
                <Form.Control
                  type="password"
                  name="password"
                  value={userForm.password}
                  onChange={handleChange}
                  placeholder="********"
                  minLength={8}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel
                label="Confirm Password *"
                className="mt-3 mt-md-0"
              >
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmChange}
                  placeholder="********"
                  required
                />
              </FloatingLabel>
            </Col>

            <Form.Label className="text-danger text-center m-0">
              {errors.password}
            </Form.Label>
          </Row>

          <Row>
            <Col md>
              <FloatingLabel label="First Name *" className="mb-3">
                <Form.Control
                  type="text"
                  name="firstName"
                  value={userForm.firstName}
                  onChange={handleChange}
                  placeholder="Jane"
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel label="Last Name *" className="mb-3">
                <Form.Control
                  type="text"
                  name="lastName"
                  value={userForm.lastName}
                  onChange={handleChange}
                  placeholder="Doe"
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FloatingLabel label="Display Name *" className="mb-3">
                <Form.Control
                  type="text"
                  name="displayName"
                  value={userForm.displayName}
                  onChange={handleChange}
                  placeholder="NY"
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel label="Zip Code *" className="mb-3">
                <Form.Control
                  type="text"
                  pattern="[0-9]{5}"
                  name="zipCode"
                  value={userForm.zipCode}
                  onChange={handleChange}
                  placeholder="10001"
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md>
              <FloatingLabel label="Street Address 1" className="mb-3">
                <Form.Control
                  type="text"
                  name="streetAddress1"
                  value={userForm.streetAddress1}
                  onChange={handleChange}
                  placeholder="123 Main Street"
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel label="Street Address 2" className="mb-3">
                <Form.Control
                  type="text"
                  name="streetAddress2"
                  value={userForm.streetAddress2}
                  onChange={handleChange}
                  placeholder="Apt A1"
                />
              </FloatingLabel>
            </Col>
          </Row>

          {userForm.role === "Parent" || userForm.role === "Both" ? (
            <FormParent userForm={userForm} handleChange={handleChange} />
          ) : null}

          {userForm.role === "Teacher" || userForm.role === "Both" ? (
            <FormTeacher userForm={userForm} handleChange={handleChange} />
          ) : null}

          {errors.submit ? (
            <div className="text-center">
              <Form.Label className="text-danger m-0">
                {errors.submit}
              </Form.Label>
            </div>
          ) : null}

          <div ref={bottomScrollRef} className="d-flex justify-content-center">
            <Button type="submit" className="mt-4 mx-auto">
              Sign Up
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default SignUp;
