import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
// import { LinkContainer } from "react-router-bootstrap";
import UserAuth from "../models/UserAuth";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import "./Header.css";
import logo from "../assets/logo-ninja.png";
import APIURL from "../models/APIURL";
import Classes from "../models/Classes";

function Header(props) {
  const { userState, aAccessState } = props;
  const [user, setUser] = useRecoilState(userState);
  const [navKey, setNavKey] = useState("home");
  const navigate = useNavigate();

  useEffect(() => {
    async function getUser() {
      try {
        const userData = await UserAuth.getUser();
        setUser(userData);
      } catch (error) {
        console.log("Failed to fetch user data.", error);
        setUser(null);
      }
    }

    // To wake up cheap sleeping Heroku back end on first visit, speed up first load
    async function pingBackend() {
      try {
        const res = await Classes.getAll();
        console.log("Heroku woke up, classes responded:", res);
      } catch (error) {
        console.log("Error waking Heroku back end:", error);
      }
    }

    if (localStorage.getItem("jwToken")) {
      getUser();
    } else if (APIURL === "https://school-ninja.herokuapp.com/api") {
      // To wake up sleeping Heroku back end on first load
      pingBackend();
      setUser(null);
    } else {
      setUser(null);
    }
  }, [setUser]);

  function logout() {
    localStorage.removeItem("jwToken");
    setUser(null);
    navigate("/");
  }

  const userNav = user ? (
    <NavDropdown
      title={`Welcome, ${user["first_name"]} `}
      id="nav-dropdown"
      className="text-center"
    >
      <NavDropdown.Item
        eventKey="user"
        as={NavLink}
        to="/userProfile"
        className="text-center"
      >
        My Profile
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey="signout"
        onClick={logout}
        className="text-center"
      >
        Sign Out
      </NavDropdown.Item>
    </NavDropdown>
  ) : (
    <div className="d-flex ms-md-2 my-2 my-md-0 text-center justify-content-center">
      <Nav.Link
        eventKey="signup"
        as={NavLink}
        to="/signUp"
        className="mx-2 mx-md-1 mx-lg-2 p-0"
      >
        <Button>Sign Up</Button>
      </Nav.Link>
      <Nav.Link
        eventKey="signin"
        as={NavLink}
        to="/signIn"
        className="mx-2 mx-md-1 mx-lg-2 p-0"
      >
        <Button variant="outline-primary">Sign In</Button>
      </Nav.Link>
      {/* <Nav.Link as={NavLink} to={"/signIn"}>
        Sign In
      </Nav.Link> */}
    </div>
  );

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        sticky="top"
        variant="light"
        className="shadow-sm py-0 px-3 px-md-2 px-lg-3"
      >
        <Nav
          activeKey={navKey}
          onSelect={(k) => setNavKey(k)}
          className="m-0 p-0"
        >
          <Nav.Link eventKey="home" as={NavLink} to="/" className="m-0 p-0">
            <Navbar.Brand className="m-0 p-0">
              <img className="logo" src={logo} alt="School Ninja logo" />
            </Navbar.Brand>
          </Nav.Link>
        </Nav>

        <Navbar.Toggle aria-controls="navbar-nav" className="me-3" />
        <Navbar.Collapse id="navbar-nav" className="pb-2 pb-md-0">
          <Nav
            activeKey={navKey}
            onSelect={(k) => setNavKey(k)}
            fill
            className="ms-auto me-3"
          >
            <Nav.Link eventKey="community" as={NavLink} to="/community">
              COMMUNITY
            </Nav.Link>
            <Nav.Link eventKey="classes" as={NavLink} to="/classes">
              CLASSES
            </Nav.Link>
            <Nav.Link eventKey="teachers" as={NavLink} to="/teachers">
              TEACHERS
            </Nav.Link>
            <Nav.Link
              eventKey="meetup"
              as={NavLink}
              to="/meetUp"
              className="me-md-2 me-lg-4"
            >
              MEETUP
            </Nav.Link>
            {userNav}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Outlet context={{ userState, aAccessState }} />
    </>
  );
}

export default Header;
