const AgeCalc = (dob) => {
    const today = new Date();
    const bday = new Date(dob);
    const months = today.getMonth() - bday.getMonth();
    let age = today.getFullYear() - bday.getFullYear();
    if (months < 0 || (months === 0 && today.getDate() < bday.getDate())) {
        age--;
    }
    return age;
}

export default AgeCalc;