import { useOutletContext } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { LinkContainer } from 'react-router-bootstrap';
import AgeCalc from '../models/AgeCalc';

const ParentsList = () => {
  const { parents } = useOutletContext();

  return parents.map((parent) => (
    <LinkContainer key={parent['id']} parent={parent} to={`${parent.id}`}>
      <Card className="parents-list-card">
        <div className="parents-list-card-img-div me-4">
          <img
            src={parent.img_url}
            className="parents-list-card-img"
            alt="Family"
          />
        </div>
        <div className="parents-list-card-body ms-2 my-2">
          <h3 style={{ color: '#033E80' }}>{parent['display_name']}</h3>
          <p>
            1 mi from you: <small>{parent['zip_code']}</small>
          </p>
          {parent['child1_DOB'] ? (
            <h6>
              {AgeCalc(parent['child1_DOB'])} year old {parent['child1_sex']}
            </h6>
          ) : null}
          {parent['child2_DOB'] ? (
            <h6>
              {AgeCalc(parent['child2_DOB'])} year old {parent['child2_sex']}
            </h6>
          ) : null}
          {parent['child3_DOB'] ? (
            <h6>
              {AgeCalc(parent['child3_DOB'])} year old {parent['child3_sex']}
            </h6>
          ) : null}
          {parent['child4_DOB'] ? (
            <h6>
              {AgeCalc(parent['child4_DOB'])} year old {parent['child4_sex']}
            </h6>
          ) : null}
        </div>
      </Card>
    </LinkContainer>
  ));
};

export default ParentsList;
