import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ZipSearch from "../components/ZipSearch";

const MeetUp = () => {
  
  const cardStyle = {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "24px",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
    cursor: "pointer",
  };

  return (
    <Container fluid="md" className="mt-4 pt-2 text-center">
      <h1>MeetUp</h1>
      <h5 className="my-3">Post and attend field trips, playdates, and activities with other families!</h5>
      <ZipSearch />
      <Card style={cardStyle}>
        <Row className="align-items-center">
          <Col s={7} className="text-start">
            <h3 style={{ color: "#033E80" }}>Get Together in Central Park</h3>
            <h6 className="mb-1">Organized by FunFam </h6>
            <p className="mb-1">
              All ages welcome!
            </p>
          </Col>
          <Col className="text-end">
            <p className="mb-1">
              Monday 10am
            </p>
            <p className="mb-1">
              Central Park East
            </p>
            <p className="mb-1">Social</p>
          </Col>
        </Row>
      </Card>
      <Card style={cardStyle}>
        <Row className="align-items-center">
          <Col s={7} className="text-start">
            <h3 style={{ color: "#033E80" }}>Trip to the Museum</h3>
            <h6 className="mb-1">Organized by The Jones Family </h6>
            <p className="mb-1">
              Ages 8+
            </p>
          </Col>
          <Col className="text-end">
            <p className="mb-1">
              Wednesday 11am
            </p>
            <p className="mb-1">
              Museum of Natural History
            </p>
            <p className="mb-1">Educational</p>
          </Col>
        </Row>
      </Card>
      <Card style={cardStyle}>
        <Row className="align-items-center">
          <Col s={7} className="text-start">
            <h3 style={{ color: "#033E80" }}>Trick or Treat!</h3>
            <h6 className="mb-1">Organized by The Sullivans </h6>
            <p className="mb-1">
              All ages welcome!
            </p>
          </Col>
          <Col className="text-end">
            <p className="mb-1">
              Friday 5pm
            </p>
            <p className="mb-1">
              Central Park East
            </p>
            <p className="mb-1">Social</p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default MeetUp;
