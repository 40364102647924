import { useOutletContext, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AgeCalc from '../models/AgeCalc';

const ParentShow = () => {
  const { parents } = useOutletContext();
  const { id } = useParams();
  let parent = parents.filter((parent) => parent['id'] === Number(id))[0];

  return (
    <Card className="parent-show-card">
      <div className="parent-show-card-img-div me-4 mt-2">
        <img
          src={parent.img_url}
          className="parent-show-card-img"
          alt="Family"
        />
      </div>
      <div className="parent-show-card-body m-2">
        <h3>{parent['display_name']}</h3>
        <p>
          1 mi from you: <small>{parent['zip_code']}</small>
        </p>
        {parent['child1_DOB'] ? (
          <h6>
            {AgeCalc(parent['child1_DOB'])} year old {parent['child1_sex']}
          </h6>
        ) : null}
        {parent['child2_DOB'] ? (
          <h6>
            {AgeCalc(parent['child2_DOB'])} year old {parent['child2_sex']}
          </h6>
        ) : null}
        {parent['child3_DOB'] ? (
          <h6>
            {AgeCalc(parent['child3_DOB'])} year old {parent['child3_sex']}
          </h6>
        ) : null}
        {parent['child4_DOB'] ? (
          <h6>
            {AgeCalc(parent['child4_DOB'])} year old {parent['child4_sex']}
          </h6>
        ) : null}
        <h6 className="my-4">{parent['about']}</h6>
        <h6 className="mt-3">
          <em>{parent['hashtags']}</em>
        </h6>
        <div className="mt-4">
          <Button>Connect</Button>
        </div>
      </div>
    </Card>
  );
};

export default ParentShow;
