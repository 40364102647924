import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Classes from '../models/Classes';

const ClassShow = () => {
  const { id } = useParams();
  const { userState } = useOutletContext();
  const [selectedClass, setSelectedClass] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  useEffect(() => {
    async function getClass() {
      const res = await Classes.getOne(id);
      setSelectedClass(res);
    }
    getClass();
  }, [id]);

  const handleDelete = async () => {
    const deleted = await Classes.delete(id);
    if (deleted) {
      navigate('/classes');
    }
  };

  const DeleteConfirm = () => (
    <div className="m-3">
      <h4>Are you sure you want to delete your class?</h4>
      <h4>This cannot be undone.</h4>
      <Button
        className="m-2"
        onClick={() => setShowDelete(false)}
        variant="secondary"
      >
        No, Go Back!
      </Button>
      <Button className="m-2" onClick={handleDelete} variant="danger">
        Yes, Delete This Class
      </Button>
    </div>
  );

  const cardStyleAboutMe = {
    minWidth: '30%',
    maxWidth: '50%',
    margin: '20px 10px',
    padding: '24px',
    backgroundColor: '#f6f9ff',
    textAlign: 'center',
    color: '#000',
  };

  const cardStyleAboutClass = {
    minWidth: '50%',
    maxWidth: '70%',
    margin: '20px 10px',
    padding: '24px',
    backgroundColor: 'rgb(238, 241, 241)',
    textAlign: 'center',
  };

  const deleteStyle = {
    color: '#900',
    cursor: 'pointer',
  };

  return selectedClass ? (
    <Container fluid="md" className="mt-4 pt-2 pb-5 text-center">
      <h1 className="text-center">Classes</h1>
      <h5 className="my-3">
        Find and join local classes, co-ops, and learning pods!
      </h5>

      <Container fluid="md" className="d-flex justify-content-center">
        <Card style={cardStyleAboutClass}>
          <h2>{selectedClass.name}</h2>
          <h5 className="mt-3">
            Ages {selectedClass['ages']} &nbsp; | &nbsp;{' '}
            {selectedClass['day_of_week']} {selectedClass['time']}
          </h5>
          <h6 className="my-4">{selectedClass['about']}</h6>
          <h5 className="mt-1">
            {selectedClass['location']} &nbsp; {selectedClass['zip_code']}
          </h5>
          <h6 className="mt-1">
            <em>Capacity:</em> {selectedClass['capacity']}
          </h6>
          <h6 className="mt-0">
            <em>Classes per semester:</em>{' '}
            {selectedClass['classes_per_semester']}
          </h6>
          <h6 className="mt-0">{selectedClass['dates']}</h6>
          <h5 className="mt-3">Cost: ${selectedClass['cost']}</h5>

          {user && user['id'] === selectedClass['teacher_id'] ? (
            <Container>
              {showDelete ? (
                <DeleteConfirm />
              ) : (
                <div className="mt-4">
                  <Button variant="primary" as={Link} to={'/classUpdate/' + id}>
                    Update My Class
                  </Button>
                  <p className="mt-4">
                    <small
                      onClick={() => setShowDelete(true)}
                      style={deleteStyle}
                    >
                      Delete My Class
                    </small>
                  </p>
                </div>
              )}
            </Container>
          ) : null}
        </Card>
        <Card style={cardStyleAboutMe} as={Link} to={`../teachers/${selectedClass.teacher_id}`}>
          {/* <LinkContainer> */}
            {/* <> */}
              <h4 className="mb-2">About the Teacher</h4>
              <div>
                <img
                  src={selectedClass['teacher_id__teach_img_url']}
                  className="class-teacher-img"
                  alt="Teacher"
                />
              </div>
              <h4 className="mt-1 mb-3">
                {selectedClass['teacher_id__display_name']}
              </h4>
              <h6 className="mb-3">
                {selectedClass['teacher_id__first_name']}{' '}
                {selectedClass['teacher_id__last_name']}
              </h6>
              <h6 className="mb-1">
                <em>About me: </em>
              </h6>
              <h6 className="mb-3">
                {selectedClass['teacher_id__teach_about']}
              </h6>
            {/* </> */}
          {/* </LinkContainer> */}
        </Card>
      </Container>
    </Container>
  ) : (
    <Container className="mt-4">
      <h1 className="text-center">Classes</h1>
      <h1 className="mt-5 text-center">Loading...</h1>
    </Container>
  );
};

export default ClassShow;
