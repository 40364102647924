import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FormParent = (props) => {
  const { userForm, handleChange } = props;

  return (
    <>
      <h4 className="text-center mt-4">Parent/Family Profile</h4>
      <FloatingLabel label="About Us" className="mb-3">
        <Form.Control
          as="textarea"
          style={{ height: "100px" }}
          name="about"
          value={userForm.about}
          onChange={handleChange}
          placeholder="Tell the community about yourself and what you're looking for!"
        />
      </FloatingLabel>
      <FloatingLabel label="#hashtags" className="mb-3">
        <Form.Control
          type="text"
          name="hashtags"
          value={userForm.hashtags}
          onChange={handleChange}
          placeholder="#homeschooling"
        />
      </FloatingLabel>
      <Row>
        <Col md>
          <FloatingLabel label="Child 1 Birthdate *" className="mb-3">
            <Form.Control
              type="date"
              name="child1DOB"
              value={userForm.child1DOB}
              onChange={handleChange}
              placeholder="2010-01-01"
              required
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel label="Child 1 Sex *" className="mb-3">
            <Form.Select
              aria-label="Child 1 Sex"
              name="child1Sex"
              value={userForm.child1Sex}
              onChange={handleChange}
              placeholder="Girl"
              required
            >
              <option></option>
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md>
          <FloatingLabel label="Child 2 Birthdate" className="mb-3">
            <Form.Control
              type="date"
              name="child2DOB"
              value={userForm.child2DOB}
              onChange={handleChange}
              placeholder="2010-01-01"
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel label="Child 2 Sex" className="mb-3">
            <Form.Select
              aria-label="Child 2 Sex"
              name="child2Sex"
              value={userForm.child2Sex}
              onChange={handleChange}
              placeholder="Girl"
            >
              <option></option>
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md>
          <FloatingLabel label="Child 3 Birthdate" className="mb-3">
            <Form.Control
              type="date"
              name="child3DOB"
              value={userForm.child3DOB}
              onChange={handleChange}
              placeholder="2010-01-01"
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel label="Child 3 Sex" className="mb-3">
            <Form.Select
              aria-label="Child 3 Sex"
              name="child3Sex"
              value={userForm.child3Sex}
              onChange={handleChange}
              placeholder="Girl"
            >
              <option></option>
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md>
          <FloatingLabel label="Child 4 Birthdate" className="mb-3">
            <Form.Control
              type="date"
              name="child4DOB"
              value={userForm.child4DOB}
              onChange={handleChange}
              placeholder="2010-01-01"
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel label="Child 4 Sex" className="mb-3">
            <Form.Select
              aria-label="Child 4 Sex"
              name="child4Sex"
              value={userForm.child4Sex}
              onChange={handleChange}
              placeholder="Girl"
            >
              <option></option>
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <p className="text-center mb-4">
        <small className="text-center">
          Children's birthdates are only used to show approximate ages on your
          profile <br />
          (e.g. "8-year-old girl, 6-year-old boy...")
        </small>
      </p>
    </>
  );
};

export default FormParent;
