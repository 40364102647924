import { useOutletContext } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import AgeCalc from "../models/AgeCalc";

const UserProfile = () => {
  const { userState } = useOutletContext();
  const user = useRecoilValue(userState);
  console.log(user);

  const cardStyle = {
    maxWidth: "700px",
    margin: "20px auto",
    padding: "12px",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
  };
  const innerCardStyle = {
    minWidth: "80%",
    maxWidth: "95%",
    margin: "0 auto 12px",
    padding: "12px",
    backgroundColor: "#fafdff",
    textAlign: "center",
  };
  const deleteStyle = {
    color: "#800",
  };

  return (
    <Container className="mt-4 pt-2 mb-5 text-center">
      {user ? (
        <>
          <h1>My Profile</h1>
          <Card style={cardStyle}>
            <p className="mt-2 mb-0">
              <small>
                <em>Public Profile:</em>
              </small>
            </p>
            <Card style={innerCardStyle}>
              <h3 className="my-1">{user["display_name"]}</h3>
              <h6 className="mb-3">
                <small>
                  <em>Zip Code:</em>
                </small>{" "}
                {user["zip_code"]}
              </h6>
              {user.role === "Parent" || user.role === "Both" ? (
                <div className="mt-1">
                  {user.role === "Both" ? (
                    <small>
                      <em>Parent/Family Profile:</em>
                    </small>
                  ) : null}
                  <div>
                    <img
                      src={user["img_url"]}
                      className="parent-profile-card-img m-2"
                      alt="Family"
                    />
                  </div>
                  <h6 className="my-2">
                    <small>About us: </small> {user["about"]}
                  </h6>
                  <h6 className="my-3">{user["hashtags"]}</h6>
                  {user["child1_DOB"] ? (
                    <h6>
                      {AgeCalc(user["child1_DOB"])}-year-old{" "}
                      {user["child1_sex"]}
                    </h6>
                  ) : null}
                  {user["child2_DOB"] ? (
                    <h6>
                      {AgeCalc(user["child2_DOB"])}-year-old{" "}
                      {user["child2_sex"]}
                    </h6>
                  ) : null}
                  {user["child3_DOB"] ? (
                    <h6>
                      {AgeCalc(user["child3_DOB"])}-year-old{" "}
                      {user["child3_sex"]}
                    </h6>
                  ) : null}
                  {user["child4_DOB"] ? (
                    <h6>
                      {AgeCalc(user["child4_DOB"])}-year-old{" "}
                      {user["child4_sex"]}
                    </h6>
                  ) : null}
                </div>
              ) : null}
              {user.role === "Teacher" || user.role === "Both" ? (
                <div className="mt-1">
                  {user.role === "Both" ? (
                    <>
                      <hr
                        style={{ maxWidth: "80%", margin: "16px auto 20px" }}
                      />
                      <small>
                        <em>Teacher Profile:</em>
                      </small>
                    </>
                  ) : null}
                  <div>
                    <img
                      src={user.teach_img_url}
                      className="teacher-profile-card-img m-3"
                      alt="Teacher"
                    />
                  </div>
                  <h6 className="my-2">
                    <small>About me: </small> {user.teach_about}
                  </h6>
                  <h6 className="my-2">
                    <small>Subjects: </small> {user.teach_subjects}
                  </h6>
                  <h6 className="my-2">
                    <small>Grade Levels: </small> {user.teach_grade_levels}
                  </h6>
                  <h6 className="my-2">
                    <small>In-Person or Online: </small>{" "}
                    {user.teach_inperson_online}
                  </h6>
                  <h6 className="my-2">
                    <small>Group or Private: </small> {user.teach_group_private}
                  </h6>
                </div>
              ) : null}
            </Card>
            <p className="mt-2 mb-1">
              <small>
                <em>Private Account Info:</em>
              </small>
            </p>
            <Card style={innerCardStyle}>
              <h4 className="mb-2">
                {user["first_name"]} {user["last_name"]}
              </h4>
              <h6 className="mb-2">{user["username"]}</h6>
              <h6 className="mb-2">
                {user["street_address_1"]} <br /> {user["street_address_2"]}
              </h6>
            </Card>
            <div className="my-4">
              <Button as={Link} to="/userUpdate">
                Update My Account
              </Button>
            </div>
            <p className="my-3">
              <small>
                <Link to="/userDelete" style={deleteStyle}>
                  Delete my account
                </Link>
              </small>
            </p>
          </Card>
        </>
      ) : (
        <Card style={cardStyle}>
          <h2 className="mb-4">My Profile</h2>
          <h5 className="mb-4">You are not signed in.</h5>
          <div>
            <Button as={Link} to="/signIn">
              Sign In
            </Button>
            &nbsp;&nbsp; or &nbsp;&nbsp;
            <Button as={Link} to="/signUp">
              Sign Up
            </Button>
          </div>
          <p className="mt-2">to view your profile.</p>
        </Card>
      )}
    </Container>
  );
};

export default UserProfile;
