import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Parents from "../models/Parents";
import ZipSearch from "../components/ZipSearch";

const Community = () => {
  const [parents, setParents] = useState([]);

  useEffect(() => {
    async function getParents() {
      const res = await Parents.getAll();
      setParents(res);
    }
    getParents();
  }, []);

  const loaded = () => {
    return <Outlet context={{ parents }} />;
  };

  return (
    <Container fluid="md" className="mt-4 pt-2 text-center">
      <h1>Community</h1>
      <h5 className="my-3">Connect with homeschooling families in your neighborhood!</h5>
      <ZipSearch />
      {parents.length ? loaded() : <h4>Loading...</h4>}
    </Container>
  );
};

export default Community;
