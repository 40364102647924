import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Classes from "../models/Classes";

const ClassUpdate = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [updateForm, setUpdateForm] = useState({
    title: "",
    about: "",
    cost: 0,
    teacher_id: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "cost") {
      setUpdateForm({
        ...updateForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setUpdateForm({ ...updateForm, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedClass = await Classes.update(id, updateForm);
    if (updatedClass) {
      console.log("Class update succeeded.");
      navigate("/classes/" + id);
    } else {
      console.log("Class update failed.");
      setErrors({ submit: "Couldn't update class. Please try again." });
    }
  };

  useEffect(() => {
    async function getClass() {
      const res = await Classes.getOne(id);
      setUpdateForm(res);
      console.log(res);
    }
    getClass();
  }, [id]);

  const cardStyle = {
    maxWidth: "700px",
    margin: "20px auto",
    padding: "24px",
    backgroundColor: "rgb(238, 241, 241)",
  };

  return (
    <Container className="mt-4 pt-2">
      <Card style={cardStyle}>
        <h2 className="text-center">Update Your Class</h2>
        <Form onSubmit={handleSubmit} className="mt-3 mb-2">
          <FloatingLabel label="Class Title" className="mb-3">
            <Form.Control
              type="text"
              name="title"
              value={updateForm.title}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Cost" className="mb-3">
            <Form.Control
              type="number"
              name="cost"
              value={updateForm.cost}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Description" className="mb-3">
            <Form.Control
              type="textarea"
              name="about"
              value={updateForm.about}
              onChange={handleChange}
            />
          </FloatingLabel>

          {errors.submit ? (
            <div className="text-center">
              <Form.Label className="text-danger m-0">
                {errors.submit}
              </Form.Label>
            </div>
          ) : null}

          <div className="text-center">
            <Button type="submit" className="m-3">
              Update Class
            </Button>
            <Button
              as={Link}
              to={"/classes/" + id}
              variant="secondary"
              className="m-3"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default ClassUpdate;
