import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FormTeacher = (props) => {
  const { userForm, handleChange } = props;

  return (
    <>
      <h4 className="text-center mt-4">Teacher Profile</h4>
      <FloatingLabel label="About Me *" className="mb-3">
        <Form.Control
          as="textarea"
          style={{ height: "100px" }}
          name="teachAbout"
          value={userForm.teachAbout}
          onChange={handleChange}
          placeholder="Tell parents about yourself as a teacher!"
          required
        />
      </FloatingLabel>
      <FloatingLabel label="Subjects *" className="mb-3">
        <Form.Control
          type="text"
          name="teachSubjects"
          value={userForm.teachSubjects}
          onChange={handleChange}
          placeholder="Math, History..."
          required
        />
      </FloatingLabel>
      <FloatingLabel label="Grade Levels *" className="mb-3">
        <Form.Control
          type="text"
          name="teachGradeLevels"
          value={userForm.teachGradeLevels}
          onChange={handleChange}
          placeholder="K-5, Middle School..."
          required
        />
      </FloatingLabel>
      <Row>
        <Col md>
          <FloatingLabel label="In-Person or Online *" className="mb-3">
            <Form.Select
              name="teachInpersonOnline"
              value={userForm.teachInpersonOnline}
              onChange={handleChange}
              placeholder="Both In-Person and Online"
              required
            >
              <option></option>
              <option value="In-Person">In-Person</option>
              <option value="Online">Online</option>
              <option value="Both">Both In-Person and Online</option>
            </Form.Select>
          </FloatingLabel>
          
        </Col>
        <Col md>
          <FloatingLabel label="Group or Private Lessons *" className="mb-3">
            <Form.Select
              name="teachGroupPrivate"
              value={userForm.teachGroupPrivate}
              onChange={handleChange}
              placeholder="Both Group and Private"
              required
            >
              <option></option>
              <option value="Group">Group</option>
              <option value="Private">Private</option>
              <option value="Both">Both Group and Private</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
};

export default FormTeacher;
