import { useOutletContext, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const TeacherShow = () => {
  const { teachers } = useOutletContext();
  const { id } = useParams();
  let teacher = teachers.filter((teacher) => teacher['id'] === Number(id))[0];

  return (
    <Card className="teacher-show-card">
      <div className="teacher-show-card-img-div me-4 mt-2">
        <img
          src={teacher.teach_img_url}
          className="teacher-show-card-img"
          alt="Teacher"
        />
      </div>
      <div className="teacher-show-card-body m-2">
        <h3>{teacher['display_name']}</h3>
        <h6>
          {teacher['first_name']} {teacher['last_name']}
        </h6>
        <p>
          1 mi from you: <small>{teacher['zip_code']}</small>
        </p>
        <h6>
          <small>
            <em>Subjects:</em>
          </small>{' '}
          {teacher['teach_subjects']}
        </h6>
        <h6>
          <small>
            <em>Grade Levels:</em>
          </small>{' '}
          {teacher['teach_grade_levels']}
        </h6>
        <h6>
          <small>
            <em>In Person or Online:</em>
          </small>{' '}
          {teacher['teach_inperson_online']}
        </h6>
        <h6>
          <small>
            <em>Group Classes or Private Lessons:</em>
          </small>{' '}
          {teacher['teach_group_private']}
        </h6>
        <h6 className="my-4">{teacher['teach_about']}</h6>
        <h6 className="mt-3">
          <em>{teacher['hashtags']}</em>
        </h6>
        <div className="mt-4">
          <Button>Connect</Button>
        </div>
      </div>
    </Card>
  );
};

export default TeacherShow;
