import { useOutletContext } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { LinkContainer } from 'react-router-bootstrap';

const TeachersList = () => {
  const { teachers } = useOutletContext();

  return teachers.map((teacher) => (
    <LinkContainer key={teacher['id']} teacher={teacher} to={`${teacher.id}`}>
      <Card className="teachers-list-card">
        <div className="teachers-list-card-img-div me-4">
          <img
            src={teacher.teach_img_url}
            className="teachers-list-card-img"
            alt="Teacher"
          />
        </div>
        <div className="teachers-list-card-body ms-2 my-2">
          <h3 style={{ color: '#033E80' }}>{teacher['display_name']}</h3>
          <p>
            1 mi from you: <small>{teacher['zip_code']}</small>
          </p>
          <h6>
            <small>
              <em>Subjects:</em>
            </small>{' '}
            {teacher['teach_subjects']}
          </h6>
          <h6>
            <small>
              <em>Grade Levels:</em>
            </small>{' '}
            {teacher['teach_grade_levels']}
          </h6>
          <h6>
            <small>
              <em>In Person or Online:</em>
            </small>{' '}
            {teacher['teach_inperson_online']}
          </h6>
        </div>
      </Card>
    </LinkContainer>
  ));
};

export default TeachersList;
