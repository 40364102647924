import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import UserAuth from "../models/UserAuth";

const SignIn = () => {
  const { userState } = useOutletContext();
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loggedInUser = await UserAuth.login(loginForm);
    if (loggedInUser) {
      console.log("Login succeeded.");
      setUser(loggedInUser);
      navigate("/community");
    } else {
      console.log("Login failed.");
      setError("Invalid credentials. Please try again.");
    }
  };

  return (
    <Container className="mt-4 pt-1">
      <Card className="sign-in-card">
        <h2 className="text-center">Sign In</h2>
        <Form onSubmit={handleSubmit} className="mt-3 mb-2">
          <FloatingLabel label="Email Address" className="mb-3">
            <Form.Control
              type="text"
              id="username"
              name="username"
              value={loginForm.email}
              onChange={handleChange}
              placeholder="janedoe123@example.com"
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Password" className="mb-3">
            <Form.Control
              type="password"
              id="password"
              name="password"
              value={loginForm.password}
              onChange={handleChange}
              placeholder="********"
              required
            />
          </FloatingLabel>

          {error ? (
            <div className="text-center">
              <Form.Label className="text-danger m-0">{error}</Form.Label>
            </div>
          ) : null}

          <div className="d-flex justify-content-center">
            <Button type="submit" className="mt-3 mx-auto">
              Sign In
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default SignIn;
