import APIURL from "./APIURL";

const classesURL = `${APIURL}/classes`;

class Classes {
  static getAll = async () => {
    try {
      const res = await fetch(`${classesURL}/all/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const parsedRes = await res.json();
        return parsedRes;
      } else {
        console.log("Failed to fetch classes:", res);
        return null;
      }
    } catch (err) {
      console.log("Error getting classes:", err);
      return null;
    }
  };

  static getOne = async (id) => {
    try {
      const res = await fetch(`${classesURL}/${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const parsedRes = await res.json();
        return parsedRes;
      } else {
        console.log("Failed to fetch class:", res);
        return null;
      }
    } catch (err) {
      console.log("Error getting class:", err);
      return null;
    }
  };

  static create = async (formData) => {
    try {
      const res = await fetch(`${classesURL}/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (res.status === 201) {
        console.log("Successfully created new class:", res);
        return true;
      } else {
        console.log("Failed to create new class:", res);
        return false;
      }
    } catch (err) {
      console.log("Error creating new class:", err);
      return false;
    }
  };

  static update = async (id, updateForm) => {
    try {
      const res = await fetch(`${classesURL}/${id}/update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateForm),
      });
      if (res.status === 201) {
        console.log("Class updated successfully:", res);
        return true;
      } else {
        console.log("Failed to update class:", res);
        return false;
      }
    } catch (err) {
      console.log("Error updating class:", err);
      return false;
    }
  };

  static delete = async (id) => {
    try {
      const res = await fetch(`${classesURL}/${id}/delete/`, {
        method: "DELETE",
      });
      if (res.status === 204) {
        console.log("Successfully deleted class:", res);
        return true;
      } else {
        console.log("Failed to delete class:", res);
        return false;
      }
    } catch (err) {
      console.log("Error deleting class:", err);
      return false;
    }
  };
}

export default Classes;
