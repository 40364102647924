import { useState } from "react";
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Classes from "../models/Classes";

const UserUpdate = () => {
  const { userState } = useOutletContext();
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  const [classForm, setClassForm] = useState({
    title: "",
    about: "",
    cost: 0,
    teacher_id: user ? user.id : null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "cost") {
      setClassForm({
        ...classForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setClassForm({ ...classForm, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdClass = await Classes.create(classForm);

    if (createdClass) {
      console.log("New class creation succeeded.");
      navigate("/classes");
    } else {
      console.log("Class creation failed.");
      setErrors({ submit: "Couldn't create class. Please try again." });
    }
  };

  const cardStyle = {
    maxWidth: "700px",
    margin: "20px auto",
    padding: "24px",
    backgroundColor: "#f6f9ff",
  };

  return (
    <Container className="mt-4 pt-2">
      <Card style={cardStyle}>
        <h2 className="text-center">Create a New Class</h2>
        <Form onSubmit={handleSubmit} className="mt-3 mb-2">
          <FloatingLabel label="Class Title" className="mb-3">
            <Form.Control
              type="text"
              name="title"
              value={classForm.title}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Cost" className="mb-3">
            <Form.Control
              type="number"
              name="cost"
              value={classForm.cost}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Description" className="mb-3">
            <Form.Control
              type="textarea"
              name="about"
              value={classForm.about}
              onChange={handleChange}
            />
          </FloatingLabel>

          {errors.submit ? (
            <div className="text-center">
              <Form.Label className="text-danger m-0">
                {errors.submit}
              </Form.Label>
            </div>
          ) : null}

          <div className="text-center">
            <Button type="submit" className="m-3">
              Create Class
            </Button>
            <Button as={Link} to="/classes" variant="secondary" className="m-3">
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default UserUpdate;
