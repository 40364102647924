import { useState, useRef } from "react";
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UserAuth from "../models/UserAuth";
import FormParent from "../components/FormParent";
import FormTeacher from "../components/FormTeacher";

const UserUpdate = () => {
  const { userState } = useOutletContext();
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const bottomScrollRef = useRef(null);

  // TO-DO: Make a separate route to change password/email
  // This is to update user profile

  function ShowForm() {
    const [updateForm, setUpdateForm] = useState({
      ...user,
      firstName: user.first_name,
      lastName: user.last_name,
      displayName: user.display_name,
      zipCode: user.zip_code,
      streetAddress1: user.street_address_1,
      streetAddress2: user.street_address_2,
      child1DOB: user.child1_DOB ? user.child1_DOB : "",
      child1Sex: user.child1_sex,
      child2DOB: user.child2_DOB ? user.child2_DOB : "",
      child2Sex: user.child2_sex,
      child3DOB: user.child3_DOB ? user.child3_DOB : "",
      child3Sex: user.child3_sex,
      child4DOB: user.child4_DOB ? user.child4_DOB : "",
      child4Sex: user.child4_sex,
      teachSubjects: user.teach_subjects,
      teachGradeLevels: user.teach_grade_levels,
      teachInpersonOnline: user.teach_inperson_online,
      teachGroupPrivate: user.teach_group_private,
      teachAbout: user.teach_about,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      setUpdateForm({ ...updateForm, [e.target.name]: e.target.value });
    };

    const handleRoleChange = (e) => {
      console.log(e);
      setUpdateForm({ ...updateForm, role: e });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const updatedUser = await UserAuth.update(updateForm);
      if (updatedUser) {
        console.log("User update succeeded.");
        setUser(updatedUser);
        navigate("/userProfile");
      } else {
        console.log("User update failed.");
        bottomScrollRef.current.scrollIntoView();
        setErrors({ submit: "Couldn't update user. Please try again." });
      }
    };

    return (
      <Container className="mt-4 pt-1">
        <Card className="update-profile-card">
          <h2 className="text-center">Update My Profile</h2>
          <h6 className="text-center">{updateForm.username}</h6>
          <Form onSubmit={handleSubmit} className="mt-3 mb-2">
            <div className="d-flex justify-content-center mb-3">
              <div className="m-2 p-2">
                <h6>My role:</h6>
              </div>
              <div className="mt-1 pt-1">
                <DropdownButton
                  id="dropdown-basic-button"
                  title={updateForm.role}
                  name="role"
                  onSelect={handleRoleChange}
                  className="m-0 p-0"
                >
                  <Dropdown.Item eventKey="Parent">Parent</Dropdown.Item>
                  <Dropdown.Item eventKey="Teacher">Teacher</Dropdown.Item>
                  <Dropdown.Item eventKey="Both">
                    Parent & Teacher
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <Row>
              <Col md>
                <FloatingLabel label="First Name *" className="mb-3">
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={updateForm.firstName}
                    onChange={handleChange}
                    placeholder="Jane"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel label="Last Name *" className="mb-3">
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={updateForm.lastName}
                    onChange={handleChange}
                    placeholder="Doe"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md>
                <FloatingLabel label="Display Name *" className="mb-3">
                  <Form.Control
                    type="text"
                    name="displayName"
                    value={updateForm.displayName}
                    onChange={handleChange}
                    placeholder="NY"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel label="Zip Code *" className="mb-3">
                  <Form.Control
                    type="text"
                    pattern="[0-9]{5}"
                    name="zipCode"
                    value={updateForm.zipCode}
                    onChange={handleChange}
                    placeholder="10001"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md>
                <FloatingLabel label="Street Address 1" className="mb-3">
                  <Form.Control
                    type="text"
                    name="streetAddress1"
                    value={updateForm.streetAddress1}
                    onChange={handleChange}
                    placeholder="123 Main Street"
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel label="Street Address 2" className="mb-3">
                  <Form.Control
                    type="text"
                    name="streetAddress2"
                    value={updateForm.streetAddress2}
                    onChange={handleChange}
                    placeholder="Apt A1"
                  />
                </FloatingLabel>
              </Col>
            </Row>

            {updateForm.role === "Parent" || updateForm.role === "Both" ? (
              <FormParent userForm={updateForm} handleChange={handleChange} />
            ) : null}

            {updateForm.role === "Teacher" || updateForm.role === "Both" ? (
              <FormTeacher userForm={updateForm} handleChange={handleChange} />
            ) : null}

            {errors.submit ? (
              <div className="text-center">
                <Form.Label className="text-danger m-0">
                  {errors.submit}
                </Form.Label>
              </div>
            ) : null}

            <div ref={bottomScrollRef} className="text-center">
              <Button type="submit" className="m-3">
                Update Account
              </Button>
              <Button
                as={Link}
                to="/userProfile"
                variant="secondary"
                className="m-3"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    );
  }

  return user ? <ShowForm /> : null;
};

export default UserUpdate;
