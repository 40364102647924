import Container from "react-bootstrap/Container";
// import Card from "react-bootstrap/Card";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";

const ZipSearch = () => {
  const [newForm, setNewForm] = useState({
    zipCode: "",
  });

  const queryClasses = (zip) => {
    // Function to find all zip codes within 5 miles of the input
    // Need to create a function using the redline API
  };

  // handle change function for form
  const handleChange = (event) => {
    console.log(event.target.value);
    setNewForm({ zipCode: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    queryClasses(newForm);
    setNewForm({
      zipCode: "",
    });
  };

  return (
    <Container className="my-3 d-flex justify-content-center">
      <Form onSubmit={handleSubmit}>
        <InputGroup size="lg" className="my-2">
          {/* <InputGroup.Text className="">Zip Code</InputGroup.Text> */}
          <Form.Control
            type="text"
            value={newForm.zipCode}
            onChange={handleChange}
            placeholder="Search by Zip Code"
          />
          <Button
            type="submit"
            id="search"
            className="blue-button"
            variant="primary"
          >
            Search
          </Button>
        </InputGroup>
        {/* <input
            type="text"
            value={newForm.zipCode}
            name="zipCode"
            placeholder="Enter your 5 digit zip code here!"
            onChange={handleChange}
          />
          <input type="submit" value="Search a zip code!" /> */}
      </Form>
    </Container>
  );
};

export default ZipSearch;
