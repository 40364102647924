import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useRecoilValue } from "recoil";
import Classes from "../models/Classes";

const ClassList = () => {
  const { userState } = useOutletContext();
  const user = useRecoilValue(userState);
  const [classes, setClasses] = useState([]);

  // Get to zipcode search later
  const [newForm, setNewForm] = useState({
    zipCode: "",
  });

  useEffect(() => {
    async function getClasses() {
      const res = await Classes.getAll();
      setClasses(res);
    }
    getClasses();
  }, []);

  const queryClasses = (zip) => {
    // Function to find all zip codes within 5 miles of the input
    // Need to create a function using the redline API
  };

  // handle change function for form
  const handleChange = (event) => {
    console.log(event.target.value);
    setNewForm({ zipCode: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    queryClasses(newForm);
    setNewForm({
      zipCode: "",
    });
  };

  const cardStyle = {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "24px",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
    cursor: "pointer",
  };

  const loaded = (type) => {
    let selectedClasses = classes;
    console.log(selectedClasses)

    if (type === "teacher") {
      const myClasses = selectedClasses.filter(
        (selected) => selected.teacher_id === user.id
      );
      selectedClasses = myClasses;
    }

    return selectedClasses.map((classes) => (
      <div key={classes.id}>
        <LinkContainer fluid="md" to={`/classes/${classes.id}`} style={cardStyle}>
          <Card>
            <Row className="align-items-center">
              <Col s={7} className="text-start">
                <h3 style={{ color: "#033E80" }}>{classes.name}</h3>
                <h6 className="mb-1">{classes.teacher_id__display_name} </h6>
                <p className="mb-1">
                  <em>Ages</em> {classes.ages}
                </p>
              </Col>
              <Col className="text-end">
                <p className="mb-1">
                  {classes.day_of_week}, {classes.time}
                </p>
                <p className="mb-1">
                  {classes.location}, {classes.zip_code}
                </p>
                <p className="mb-1">${classes.cost}</p>
              </Col>
            </Row>
            {/* <img src={classes.image} alt={classes.name} /> */}
          </Card>
        </LinkContainer>
      </div>
    ));
  };

  return (
    <Container fluid="md" className="mt-4 pt-2 pb-5 text-center">
      <h1>Classes</h1>
      <h5 className="my-3">Find and join local classes, co-ops, and learning pods!</h5>
      <Container fluid="md" className="my-3 d-flex justify-content-center">
        <Form onSubmit={handleSubmit}>
          <InputGroup size="lg" className="my-2">
            {/* <InputGroup.Text className="">Zip Code</InputGroup.Text> */}
            <Form.Control
              type="text"
              value={newForm.zipCode}
              onChange={handleChange}
              placeholder="Search by Zip Code"
            />
            <Button
              type="submit"
              id="search"
              className="blue-button"
              variant="primary"
            >
              Search
            </Button>
          </InputGroup>
          {/* <input
            type="text"
            value={newForm.zipCode}
            name="zipCode"
            placeholder="Enter your 5 digit zip code here!"
            onChange={handleChange}
          />
          <input type="submit" value="Search a zip code!" /> */}
        </Form>
      </Container>
      <Container fluid="md" className="mb-5">
        {user && user.role === ("Teacher" || "Both") && classes ? (
          <div className="text-center">
            <h2 className="mt-4">My Classes</h2>
            {loaded("teacher")}
            <Button as={Link} to="/classCreate" className="my-2">
              Add New Class
            </Button>
            <h2 className="mt-4">All Classes</h2>
          </div>
        ) : null}
        {classes ? loaded("all") : null}
      </Container>
    </Container>
  );
};

export default ClassList;
