import APIURL from "./APIURL";

const accessURL = `${APIURL}/alpha-access/token/`;

class AlphaAccess {
  static getAccess = async (password) => {
    const accessCredentials = {
      username: "AA_Alpha_Access",
      password: password,
    };
    try {
      const res = await fetch(accessURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accessCredentials),
      });
      console.log("ALPHA ACCESS RESPONSE:", res);

      if (res.status === 200) {
        console.log("SUCCESS. ACCESS GRANTED.");
        const parsedRes = await res.json();
        localStorage.setItem(
          "alphaAccess",
          JSON.stringify({
            token: parsedRes.access,
            expiration: new Date().getTime() + 86390000, // 24 hours minus 10 seconds
          })
        );
        return true;
      } else {
        console.log("ACCESS DENIED:", res.status, res.statusText);
        return false;
      }
    } catch (err) {
      console.log("ACCESS FAILED:", err);
      return false;
    }
  };
  
  // NOTE: Temporary alpha access check, not secure, but doesn't need to be for this right now. 
  // Not bothering to send token to back end for validation, just checking it's here and not expired.
  static checkAccess = async () => {
    const access = localStorage.getItem("alphaAccess");
    if (access) {
      const parsedAccess = JSON.parse(access);
      if (parsedAccess.expiration > new Date().getTime()) {
        return true;
      } else {
        localStorage.removeItem("alphaAccess");
        return false;
      }
    } else {
      return false;
    }
  };
}

export default AlphaAccess;
