import APIURL from "./APIURL";

const teachersURL = `${APIURL}/teachers`;

class Teachers {
  static getAll = async () => {
    try {
    //   const token = localStorage.getItem("jwToken");
      const res = await fetch(`${teachersURL}/all/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        //   Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        const parsedRes = await res.json();
        console.log("Fetched teacher profiles.", parsedRes);
        return parsedRes;
      } else {
        console.log("Failed to fetch teacher profiles:", res);
        return null;
      }
    } catch (err) {
      console.log("Error getting teacher profiles:", err);
      return null;
    }
  };
}

export default Teachers;