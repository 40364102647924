import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <Container className="mt-4 pt-2">
      <h1 className="text-center">Uh oh...</h1>
      <h2 className="mt-3 text-center">404: Page not found</h2>
      <h4 className="mt-3 text-center">Check the URL or return to our <Link to="/">Home Page</Link></h4>
    </Container>
  );
};

export default NoPage;
