import APIURL from "./APIURL";

const profilesURL = `${APIURL}/profiles`;

class Parents {
  static getAll = async () => {
    try {
    //   const token = localStorage.getItem("jwToken");
      const res = await fetch(`${profilesURL}/all/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        //   Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        const parsedRes = await res.json();
        console.log("Fetched parent profiles.", parsedRes);
        return parsedRes;
      } else {
        console.log("Failed to fetch parent profiles:", res);
        return null;
      }
    } catch (err) {
      console.log("Error getting parent profiles:", err);
      return null;
    }
  };
}

export default Parents;