import Container from "react-bootstrap/Container";

const Curriculum = () => {
  return (
    <Container className="mt-4 pt-2">
      <h1 className="text-center">Curriculum</h1>
    </Container>
  );
};

export default Curriculum;
