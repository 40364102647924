import Header from "./components/Header";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Community from "./pages/Community";
import Curriculum from "./pages/Curriculum";
import MeetUp from "./pages/MeetUp";
import Teachers from "./pages/Teachers";
import ClassList from "./pages/ClassList";
import ClassShow from "./pages/ClassShow";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import UserProfile from "./pages/UserProfile";
import UserUpdate from "./pages/UserUpdate";
import UserDelete from "./pages/UserDelete";
import ClassCreate from "./pages/ClassCreate";
import ClassUpdate from "./pages/ClassUpdate";
import { Routes, Route } from "react-router-dom";
import { RecoilRoot, atom, useRecoilState } from "recoil";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ParentsList from "./components/ParentsList";
import ParentShow from "./components/ParentShow";
import TeachersList from "./components/TeachersList";
import TeacherShow from "./components/TeacherShow";
//
import AlphaAccess from "./models/AlphaAccess";
import AlphaPasswordWall from "./pages/AlphaPasswordWall";

function App() {
  const userState = atom({ key: "user", default: null });
  const aAccessState = atom({ key: "alphaAccess", default: false });
  //

  return (
    <RecoilRoot>
      <Routes>
        <Route
          path="/"
          element={<Header userState={userState} aAccessState={aAccessState} />}
        >
          <Route path="/" element={<AlphaPasswordWall />}>
            <Route index element={<Home />} />
            <Route path="community" element={<Community />}>
              <Route index element={<ParentsList />} />
              <Route path=":id" element={<ParentShow />} />
            </Route>
            <Route path="teachers" element={<Teachers />}>
              <Route index element={<TeachersList />} />
              <Route path=":id" element={<TeacherShow />} />
            </Route>
            <Route path="meetUp" element={<MeetUp />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="signIn" element={<SignIn />} />
            <Route path="userProfile" element={<UserProfile />} />
            <Route path="userUpdate" element={<UserUpdate />} />
            <Route path="userDelete" element={<UserDelete />} />
            <Route path="classes" element={<ClassList />} />
            <Route path="classes/:id" element={<ClassShow />} />
            <Route path="classCreate" element={<ClassCreate />} />
            <Route path="classUpdate/:id" element={<ClassUpdate />} />
            <Route path="curriculum" element={<Curriculum />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Route>
      </Routes>
    </RecoilRoot>
  );
}

export default App;
