export default function MapLocationText({ contents }) {
  return (
    <div
      style={{
        color: '#f3cc57',
        background: '#033e80',
        opacity: '0.7',
        padding: '20px 20px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {contents}
    </div>
  );
}
