import APIURL from "./APIURL";

const authURL = `${APIURL}/user`;

// TODO: Refactor and clean up old user auth methods 
// and the API and improve fetch calls with the back end too,
// and fix localstorage token usage in here.

class UserAuth {
  static getUser = async () => {
    try {
      const token = localStorage.getItem("jwToken");
      const res = await fetch(`${authURL}/me/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        const parsedRes = await res.json();
        console.log("FETCHED USER DATA.", parsedRes);
        return parsedRes;
      } else {
        console.log("Could not refresh user data. Status:", res.status);
        return null;
      }
    } catch (err) {
      console.log("Failed to get user data:", err);
      return null;
    }
  };

  static register = async (formData) => {
    const newUser = {
      username: formData.email,
      password: formData.password,
    };
    const newProfile = {
      user: 0,
      role: formData.role,
      first_name: formData.firstName,
      last_name: formData.lastName,
      display_name: formData.displayName,
      zip_code: parseInt(formData.zipCode),
      street_address_1: formData.streetAddress1,
      street_address_2: formData.streetAddress2,
      about: formData.about,
      hashtags: formData.hashtags,
      child1_DOB: formData.child1DOB ? formData.child1DOB : null,
      child1_sex: formData.child1Sex,
      child2_DOB: formData.child2DOB ? formData.child2DOB : null,
      child2_sex: formData.child2Sex,
      child3_DOB: formData.child3DOB ? formData.child3DOB : null,
      child3_sex: formData.child3Sex,
      child4_DOB: formData.child4DOB ? formData.child4DOB : null,
      child4_sex: formData.child4Sex,
      teach_subjects: formData.teachSubjects,
      teach_grade_levels: formData.teachGradeLevels,
      teach_inperson_online: formData.teachInpersonOnline,
      teach_group_private: formData.teachGroupPrivate,
      teach_about: formData.teachAbout,
    };

    try {
      const userRes = await fetch(`${authURL}/register/user/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });
      const parsedResUser = await userRes.json();

      if (parsedResUser) {
        newProfile.user = parsedResUser;
        const profileRes = await fetch(`${authURL}/register/profile/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newProfile),
        });
        const parsedResProf = await profileRes.json();

        if (parsedResProf) {
          return this.login(newUser);
        } else {
          console.log("New Profile failed. Status:", profileRes.status);
          return null;
        }
      } else {
        console.log("New User registration failed. Status:", userRes.status);
        return null;
      }
    } catch (err) {
      console.log("Registration failed:", err);
      return null;
    }
  };

  static login = async (formData) => {
    try {
      const res = await fetch(`${authURL}/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const parsedRes = await res.json();
      console.log("jwToken res:", parsedRes);

      // NOTE: Current setup only using jwt access token, not refresh token.
      // I changed access token on back end to last longer (1 day), but may
      // want to refactor at some point to use refresh token as intended.
      // See TODO comment on SIMPLE_JWT in back end django settings.py
      if (parsedRes.access) {
        localStorage.setItem("jwToken", parsedRes.access);
        console.log("Logged in. Token set.");
        return this.getUser();
      } else {
        console.log("Token parse failed.");
        return null;
      }
    } catch (err) {
      console.log("Login failed:", err);
      return null;
    }
  };

  static update = async (formData) => {
    const updatedProfile = {
      user: formData.user,
      role: formData.role,
      first_name: formData.firstName,
      last_name: formData.lastName,
      display_name: formData.displayName,
      zip_code: parseInt(formData.zipCode),
      street_address_1: formData.streetAddress1,
      street_address_2: formData.streetAddress2,
      about: formData.about,
      hashtags: formData.hashtags,
      child1_DOB: formData.child1DOB ? formData.child1DOB : null,
      child1_sex: formData.child1Sex,
      child2_DOB: formData.child2DOB ? formData.child2DOB : null,
      child2_sex: formData.child2Sex,
      child3_DOB: formData.child3DOB ? formData.child3DOB : null,
      child3_sex: formData.child3Sex,
      child4_DOB: formData.child4DOB ? formData.child4DOB : null,
      child4_sex: formData.child4Sex,
      teach_subjects: formData.teachSubjects,
      teach_grade_levels: formData.teachGradeLevels,
      teach_inperson_online: formData.teachInpersonOnline,
      teach_group_private: formData.teachGroupPrivate,
      teach_about: formData.teachAbout,
    };

    try {
      const token = localStorage.getItem("jwToken");
      const res = await fetch(`${authURL}/update/profile/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedProfile),
      });
      const parsedRes = await res.json();
      console.log("parsedRes:", parsedRes);

      if (parsedRes) {
        console.log("Updated profile.");
        return parsedRes;
      } else {
        console.log("Failed to update profile. Status:", res.status);
        return null;
      }
    } catch (err) {
      console.log("Update failed:", err);
      return null;
    }
  };

  static delete = async () => {
    try {
      const token = localStorage.getItem("jwToken");
      const res = await fetch(`${authURL}/delete/user/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 204) {
        console.log("Deleted user.");
        return true;
      } else {
        console.log("Failed to delete user. Status:", res.status);
        return false;
      }
    } catch (err) {
      console.log("Error deleting user:", err);
      return false;
    }
  };
}

export default UserAuth;
