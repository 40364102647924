import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import UserAuth from "../models/UserAuth";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const UserDelete = () => {
  const { userState } = useOutletContext();
  const clearUser = useResetRecoilState(userState);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const deleted = await UserAuth.delete();
    if (deleted) {
      console.log("Deleted user.");
      localStorage.removeItem("jwToken");
      clearUser();
      navigate("/");
    } else {
      console.log("Failed to delete user.");
    }
  };

  const cardStyle = {
    maxWidth: "600px",
    margin: "20px auto",
    padding: "24px",
    backgroundColor: "#f6f9ff",
    textAlign: "center",
  };

  return (
    <Container className="mt-4 pt-1">
      <Card style={cardStyle}>
        <h2>Delete Account</h2>
        <h5 className="mt-5">Are you sure you want to delete your account?</h5>
        <h5 className="mt-3">This cannot be undone!</h5>
        <Container className="mt-5 mb-4">
          <Button
            variant="secondary"
            as={Link}
            to="/userProfile"
            className="mx-3"
          >
            No, Go Back!
          </Button>
          <Button variant="danger" onClick={handleDelete} className="mx-3">
            Yes, Delete My Account
          </Button>
        </Container>
      </Card>
    </Container>
  );
};

export default UserDelete;
