import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import title from "../assets/title.png";
import { useEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { useRecoilState } from "recoil";
import AlphaAccess from "../models/AlphaAccess";

const AlphaPasswordWall = () => {
  const { aAccessState, userState } = useOutletContext();
  const [aAccess, setAAccess] = useRecoilState(aAccessState);

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    async function checkAccess() {
      const alphaAccess = await AlphaAccess.checkAccess();
      if (alphaAccess) {
        setAAccess(true);
      }
    }
    checkAccess();
  }, [setAAccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let accessGranted = await AlphaAccess.getAccess(password);
    if (accessGranted) {
      setAAccess(true);
    } else {
      setError("Invalid password. Please try again or reach out to us.");
    }
  };

  return aAccess ? (
    <Outlet context={{ userState }} />
  ) : (
    <Container className="mt-4 pt-1">
      <div className="text-center mx-auto mt-2 mb-4">
        <img src={title} className="title-img" alt="" />
      </div>
      <Card className="sign-in-card text-center">
        <h4 className="mt-2">The School Ninja is coming soon!</h4>
        <h6 className="mt-3">
          Our site is in a private alpha development mode while we work to build
          the best community platform for learners, families, and the future of
          school!
        </h6>
        <h6 className="mt-3">
          If you've been invited to join our private alpha test community, you
          can enter the access password below:
        </h6>
        <Form onSubmit={handleSubmit} className="mx-auto my-3 text-center">
          <InputGroup size="lg" className="m-1">
            <Form.Control
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Alpha Password"
              required
              className="m-0 p-0 text-center"
            />
            <Button type="submit" className="mx-auto">
              Enter
            </Button>
          </InputGroup>

          {error ? (
            <div className="m-1 text-center">
              <Form.Text className="text-danger m-1">{error}</Form.Text>
            </div>
          ) : null}
        </Form>
      </Card>
    </Container>
  );
};

export default AlphaPasswordWall;
