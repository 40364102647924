import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import './Home.css';
import title from '../assets/title.png';
import home from '../assets/home.png';
import teacher from '../assets/teacher.png';
import Map from '../components/Map';

const Home = () => {

  return (
    <Container className="mt-5">
      <Row className="mb-5 d-flex align-items-center justify-content-center">
        <Col md="4" lg="5" className="m-1 m-md-2">
          <div className="text-center mx-auto">
            <img src={title} className="title-img" alt="" />
          </div>
          <Card className="home-inset-card">
            <h5>
              The School Ninja is a 360° education networking platform and tech
              solution connecting families, teachers, enrichment providers,
              venues, and advisors.
            </h5>
            <div className="d-flex justify-content-center mt-2">
              <LinkContainer to="/signUp">
                <Button className="blue-button" size="lg">
                  Get Started
                </Button>
              </LinkContainer>
            </div>
          </Card>
        </Col>
        <Col md className="mt-2 mb-3">
          <img src={home} className="home mt-3 mt-md-0" alt="" />
        </Col>
      </Row>
      <Card className="home-map-card">
        <Row className="d-flex align-items-center">
          <Col lg="8">
            <Map
              contents={
                <LinkContainer to={'/community'} className="home-map-link">
                  <h5>Find homeschooling families near you!</h5>
                </LinkContainer>
              }
            ></Map>
          </Col>
          <Col>
            <Card className="home-map-card-inset ms-lg-0 me-lg-4">
              <Card.Body>
                <h3>Find Your Tribe</h3>
                <Card.Text>
                  Build your local community of like-minded families and
                  teachers to help educate your children your way.
                </Card.Text>
                <Button
                  as={NavLink}
                  to={'/community'}
                  className="blue-button m-2"
                >
                  Community
                </Button>
                <Button
                  as={NavLink}
                  to={'/teachers'}
                  className="blue-button m-2"
                >
                  Teachers
                </Button>
                <Button as={NavLink} to={'/meetup'} className="blue-button m-2">
                  MeetUps
                </Button>
                <Button
                  as={NavLink}
                  to={'/classes'}
                  className="blue-button m-2"
                >
                  Classes
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="subTitle mt-5">
        <p className="mt-4">The School Ninja is the only platform:</p>
      </Row>
      <Row className="mb-5">
        <Col md="5">
          <div className="numberCircle mt-3">1</div>
          <div className="subTitle font24 contentMarginHeight">
            That is a one-stop shop for home-schoolers and hybrid-schoolers.
          </div>
          <div className="numberCircle">2</div>
          <div className="subTitle font24 contentMarginHeight">
            Connecting families based on interests, geography and educational
            preferences
          </div>
          <div className="numberCircle">3</div>
          <div className="subTitle font24 contentMarginHeight">
            Providing in person group events and classes for homeschoolers
          </div>
        </Col>
        <Col md>
          <img src={teacher} className="teacher" alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
